export const reviews = [
    {
        "_id": "67159984b59b468a08ddc120",
        "author_name": "serwis przewożny",
        "author_url": "https://www.google.com/maps/contrib/113923241225194972295/reviews",
        "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocJi3WjDyHUpVX_Hct0ssJX3oAvq2FTJFg9EDkmtM0unLkWobw=s128-c0x00000000-cc-rp-mo",
        "rating": 5,
        "relative_time_description": "2 miesiące temu",
        "text": "",
        "time": 1722007688,
        "translated": false
    },
    {
        "_id": "67159984b59b468a08ddc121",
        "author_name": "Jakub M",
        "author_url": "https://www.google.com/maps/contrib/111131930146233633006/reviews",
        "language": "pl",
        "original_language": "pl",
        "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocLYHVhBD3d29dieewXF6Wm0SPRTKBqUqXSyxsy1Hg-oPFZHvg=s128-c0x00000000-cc-rp-mo",
        "rating": 5,
        "relative_time_description": "5 miesięcy temu",
        "text": "Obsługa jak i materiały na wysokim poziomie. Wszystko załatwione zgodnie z ustaleniami. Polecam :)",
        "time": 1713936349,
        "translated": false
    },
    {
        "_id": "67159984b59b468a08ddc122",
        "author_name": "Sylwia Kaczmarek",
        "author_url": "https://www.google.com/maps/contrib/117571149068311240440/reviews",
        "language": "pl",
        "original_language": "pl",
        "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocKXxt8dxMrvgVq-PCBVxgmfscgbYAJosBQffO02tQjuFaV0lg=s128-c0x00000000-cc-rp-mo",
        "rating": 5,
        "relative_time_description": "6 miesięcy temu",
        "text": "Polecam 🙃",
        "time": 1713708826,
        "translated": false
    },
    {
        "_id": "67159984b59b468a08ddc123",
        "author_name": "lena vp",
        "author_url": "https://www.google.com/maps/contrib/100537620913737647981/reviews",
        "language": "pl",
        "original_language": "pl",
        "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocJFNGY7E4C5iZZVxokUrIF2NvzhXtF7x41ZJwFainKf-EYrKg=s128-c0x00000000-cc-rp-mo",
        "rating": 5,
        "relative_time_description": "6 miesięcy temu",
        "text": "Kupiłem podmuròwki.  Za złożenie zamówienia odpowiedzialna była bardzo miła Pani, wysłuchała nas, zaproponowała najlepsze rozwiązanie i uwzględniła wszystkie nasze życzenia.  Terminowa dostawa, staranny rozładunek, dobra jakość podmuròwköw, zwrot pieniędzy za palety - wszystko bez problemów, wszystko Super.  Polecam!",
        "time": 1713377050,
        "translated": false
    },
    {
        "_id": "67159984b59b468a08ddc124",
        "author_name": "Marcin Lew",
        "author_url": "https://www.google.com/maps/contrib/113801455399331173183/reviews",
        "language": "pl",
        "original_language": "pl",
        "profile_photo_url": "https://lh3.googleusercontent.com/a-/ALV-UjU1wF76CtpEnjoP7meTchHGtW78xqNm410DmQJl-XlHOPGGRwLC=s128-c0x00000000-cc-rp-mo-ba2",
        "rating": 5,
        "relative_time_description": "6 miesięcy temu",
        "text": "Bardzo sympatyczna współpraca, małe zamówienie, a mimo to wszystko wyjaśnione szczegółowo, pełna profeska",
        "time": 1713340191,
        "translated": false
    },
    {
        "_id": "67159984b59b468a08ddc185",
        "author_name": "B. Ś.",
        "author_url": "https://www.google.com/maps/contrib/113860558727128545513/reviews",
        "language": "pl",
        "original_language": "pl",
        "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocJC-hC4HcJ3R6RTw1Gkot3tEITABDuYIRQUnvhINtAAtRfCNw=s128-c0x00000000-cc-rp-mo-ba3",
        "rating": 5,
        "relative_time_description": "6 miesięcy temu",
        "text": "Bardzo solidna firma,solidny płot.Dorzucaja od siebie kilka płyt na wypadek gdyby któraś była uszkodzona.W naszym przypadku nie bylo uszkodzeń.Dostawa terminowa.A no i należy wspomnieć że mają duży wybór wzorów więc jest w czym wybierać.Calym sercem polecam!",
        "time": 1713257985,
        "translated": false
    },
    {
        "_id": "67159984b59b468a08ddc186",
        "author_name": "Dimmson Two",
        "author_url": "https://www.google.com/maps/contrib/105157938455122625760/reviews",
        "language": "pl",
        "original_language": "pl",
        "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocIGNLyhg69Fu7_3Ydllyah0w4y2BfEalaGy-BT54qPVs6mRslI=s128-c0x00000000-cc-rp-mo-ba4",
        "rating": 5,
        "relative_time_description": "11 miesięcy temu",
        "text": "Jakość jest doskonała, modele są oryginalne, szybka instalacja, dziękuję! Polecam!",
        "time": 1700587965,
        "translated": false
    },
    {
        "_id": "67159984b59b468a08ddc187",
        "author_name": "lena vp",
        "author_url": "https://www.google.com/maps/contrib/100537620913737647981/reviews",
        "language": "pl",
        "original_language": "pl",
        "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocJFNGY7E4C5iZZVxokUrIF2NvzhXtF7x41ZJwFainKf-EYrKg=s128-c0x00000000-cc-rp-mo",
        "rating": 5,
        "relative_time_description": "6 miesięcy temu",
        "text": "Kupiłem podmuròwki.  Za złożenie zamówienia odpowiedzialna była bardzo miła Pani, wysłuchała nas, zaproponowała najlepsze rozwiązanie i uwzględniła wszystkie nasze życzenia.  Terminowa dostawa, staranny rozładunek, dobra jakość podmuròwköw, zwrot pieniędzy za palety - wszystko bez problemów, wszystko Super.  Polecam!",
        "time": 1713377050,
        "translated": false
    },
    {
        "_id": "67159984b59b468a08ddc188",
        "author_name": "Agnieszka Grzelak",
        "author_url": "https://www.google.com/maps/contrib/115561355105471284723/reviews",
        "language": "pl",
        "original_language": "pl",
        "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocJKK-3-hjR_vKPq-T1F1FgChs5_tdCSQ1j-C9-raF9u795SVQ=s128-c0x00000000-cc-rp-mo",
        "rating": 5,
        "relative_time_description": "rok temu",
        "text": "Szybko i  sprawnie . Płot stanął w 5 dni łącznie z posprzątaniem zalegającego gruzu  w trudnym terenie . Solidna konstrukcja . Serdecznie polecam",
        "time": 1696597697,
        "translated": false
    },
    {
        "_id": "67159984b59b468a08ddc189",
        "author_name": "Marcin Lew",
        "author_url": "https://www.google.com/maps/contrib/113801455399331173183/reviews",
        "language": "pl",
        "original_language": "pl",
        "profile_photo_url": "https://lh3.googleusercontent.com/a-/ALV-UjU1wF76CtpEnjoP7meTchHGtW78xqNm410DmQJl-XlHOPGGRwLC=s128-c0x00000000-cc-rp-mo-ba2",
        "rating": 5,
        "relative_time_description": "6 miesięcy temu",
        "text": "Bardzo sympatyczna współpraca, małe zamówienie, a mimo to wszystko wyjaśnione szczegółowo, pełna profeska",
        "time": 1713340191,
        "translated": false
    }
];