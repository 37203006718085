// @ts-ignore
import { reviews } from '/tmp/reviews';

// key = 'AIzaSyDVc5BFK-QlUtdUIAjxqVZcu5lCsCQTGL8'
// place id = 'ChIJVVVVVWQVBEcRS8uWO1Ge9rM'
// https://maps.googleapis.com/maps/api/place/details/json?fields=name,rating,reviews&place_id=ChIJVVVVVWQVBEcRS8uWO1Ge9rM&key=AIzaSyDVc5BFK-QlUtdUIAjxqVZcu5lCsCQTGL8&language=pl

interface Review {
	rating: number;
	text: string;
	author_name: string;
	time: number;
}

const makeWidget = (el: HTMLElement) => {
	const max_CHARS = 320;
	const reviewsRdy: Review[] = [...reviews]
		.filter((r) => r.rating > 3)
		.filter(
			(r, i, arr) =>
				arr.findIndex((r2) => r2.author_url === r.author_url) === i
		)
		.sort((a, b) => b.time - a.time);

	for (const review of reviewsRdy) {
		const reviewEl = document.createElement('div');
		reviewEl.classList.add(
			'card',
			'is-flex',
			'is-flex-direction-column',
			'p-6'
		);

		const stars = document.createElement('div');
		stars.classList.add('stars', 'has-text-centered', 'mb-3');
		for (let i = 0; i < review.rating; i++) {
			const star = document.createElement('i');
			star.classList.add('flaticon-star', 'mx-1', 'has-text-warning');
			stars.appendChild(star);
		}
		reviewEl.appendChild(stars);

		if (review.text.trim()) {
			const text = document.createElement('div');
			text.classList.add('is-flex-grow-1', 'mb-4', 'has-text-centered');
			text.innerText =
				review.text.length > max_CHARS
					? review.text.slice(0, max_CHARS) + '...'
					: review.text;
			reviewEl.appendChild(text);
		} else {
			const text = document.createElement('div');
			text.classList.add(
				'has-text-centered',
				'is-flex-grow-1',
				'mb-2',
				'is-italic',
				'has-text-grey-light'
			);
			text.innerText = '(opinia bez treści)';
			reviewEl.appendChild(text);
		}

		const name = document.createElement('div');
		name.classList.add('has-text-weight-bold', 'has-text-centered');
		name.innerText = review.author_name
			.split(' ')
			.map((el, i) => (i === 0 ? el : el[0]))
			.join(' ');
		reviewEl.appendChild(name);

		const bottom = document.createElement('div');
		bottom.classList.add(
			'has-text-centered',
			'has-text-grey-light',
			'is-size-7',
			'mt-1'
		);
		bottom.innerHTML = `opinia dodana ${new Date(
			review.time * 1000
		).toLocaleDateString()} przez <a class="has-text-grey-light is-underlined" href="https://google.pl/maps">Google Maps</a>`;
		reviewEl.appendChild(bottom);

		el.appendChild(reviewEl);
	}
};

const activateButtons = (el: HTMLElement) => {
	const SCROLL_AMOUNT = 401;
	el.parentElement
		?.querySelector('.arrow-left button')
		?.addEventListener('click', () =>
			el.scrollBy({
				left: -SCROLL_AMOUNT,
				behavior: 'smooth',
			})
		);
	el.parentElement
		?.querySelector('.arrow-right button')
		?.addEventListener('click', () =>
			el.scrollBy({
				left: SCROLL_AMOUNT,
				behavior: 'smooth',
			})
		);

	const BUTTON_HIDE_THRESH = 40;
	el.addEventListener('scroll', () => {
		const isScrolledLeft = el.scrollLeft < BUTTON_HIDE_THRESH;
		const isScrolledRight =
			el.scrollLeft + el.clientWidth + BUTTON_HIDE_THRESH >
			el.scrollWidth;
		el.parentElement
			?.querySelector('.arrow-left button')
			?.classList.toggle('hidden', isScrolledLeft);
		el.parentElement
			?.querySelector('.arrow-right button')
			?.classList.toggle('hidden', isScrolledRight);
	});
};

document.querySelectorAll<HTMLElement>('[data-places]').forEach((el) => {
	const callback = (entries: IntersectionObserverEntry[]) => {
		if (el.dataset.placesReady) return;
		if (!entries[0].isIntersecting) return;
		el.dataset.placesReady = '1';
		makeWidget(el);
		activateButtons(el);
	};
	let observer = new IntersectionObserver(callback);
	observer.observe(el);
});
